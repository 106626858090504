import React, { useState } from "react";

const MassageTherapistCalculator = (props) => {
    const userAgent = navigator.userAgent.toLowerCase();
    const rangeFirefox = userAgent.includes("firefox") ? 'bg-blue-200 appearance-none h-1 rounded-full align-middle' : '';
    const inputFirefox = userAgent.includes("firefox") ? 'w-1/6' : '';
    let price_to = props.main_price;
    if(price_to == true){
      price_to = 'main_price'
    } else {
      price_to = 'secondary_price'
    }
    let payoutInit = calculatePrice(props.provider_price, 1, 0, 0, props.gratuity);
    let payoutPremiumPriceInit = calculatePrice(props.provider_price, 1.5, 0, 0.05, props.gratuity);
    let payoutPremiumPlusPriceInit = calculatePrice(props.provider_price, 2, 0, 0.1, props.gratuity);
    let payoutFourPriceInit = calculatePrice(props.provider_price, 4, 0, props.four_session_discount, props.gratuity);
    let payoutEightPriceInit = calculatePrice(props.provider_price, 8, 0, props.eight_session_discount, props.gratuity);
    let payoutTwelvePriceInit = calculatePrice(props.provider_price, 12, 0, props.twelve_session_discount, props.gratuity);
    let priceWithGratuity = props.provider_price + (props.provider_price * props.gratuity/100.0)
    const [price, setPrice] = useState(priceWithGratuity);
    const [standard, setStandard] = useState(payoutInit);
    const [premium, setPremium] = useState(payoutPremiumPriceInit);
    const [premiumPlus, setPremiumPlus] = useState(payoutPremiumPlusPriceInit);
    const [fourSessions, setFourSessions] = useState(payoutFourPriceInit);
    const [eightSessions, setEightSessions] = useState(payoutEightPriceInit);
    const [twelveSessions, setTwelveSessions] = useState(payoutTwelvePriceInit);
    const handlePriceChange = event => {
      let inputMainPrice = document.getElementById("main-price-input");
      let inputSecondaryPrice = document.getElementById("secondary-price-input");
      if(price_to === "main_price"){
        inputMainPrice.value = event.target.value;
      } else {
        inputSecondaryPrice.value = event.target.value;
      }
      let inputInt = document.getElementById("price-mt-int-" + price_to);
      inputInt.value = event.target.value;
      let priceWithGratuity = parseFloat(event.target.value) + (parseFloat(event.target.value) * props.gratuity/100.0)
      setPrice(priceWithGratuity);
      let payout = calculatePrice(event.target.value, 1, 0, 0, props.gratuity);
      let payoutPremiumPrice = calculatePrice(event.target.value, 1.5, 0, 0.05, props.gratuity);
      let payoutPremiumPlusPrice = calculatePrice(event.target.value, 2, 0, 0.1, props.gratuity);
      let payoutFourPrice = calculatePrice(event.target.value, 4, 0, props.four_session_discount, props.gratuity);
      let payoutEightPrice = calculatePrice(event.target.value, 8, 0, props.eight_session_discount, props.gratuity);
      let payoutTwelvePrice = calculatePrice(event.target.value, 12, 0, props.twelve_session_discoun, props.gratuity);
      setStandard(payout);
      setPremium(payoutPremiumPrice);
      setPremiumPlus(payoutPremiumPlusPrice);
      setFourSessions(payoutFourPrice);
      setEightSessions(payoutEightPrice);
      setTwelveSessions(payoutTwelvePrice);
    };

    const handlePriceInputChange = event => {
      let submitB = document.getElementById("submit-rate-btn");
      let inputRange = document.getElementById("price-mt-" + price_to);
      inputRange.value = event.target.value;
      let inputMainPrice = document.getElementById("main-price-input");
      let inputSecondaryPrice = document.getElementById("secondary-price-input");
      if(price_to === "main_price"){
        inputMainPrice.value = event.target.value;
      } else {
        inputSecondaryPrice.value = event.target.value;
      }
      if(event.target.value < props.range_min || event.target.value > props.range_max){
        event.target.style.backgroundColor = 'pink';
        submitB.setAttribute('disabled', 'true');
        submitB.style.backgroundColor = 'gray';
      } else {
        event.target.style.backgroundColor = 'white';
        submitB.removeAttribute('disabled');
        submitB.style.backgroundColor = '#06325e';
      }
      let priceWithGratuity = parseFloat(event.target.value) + (parseFloat(event.target.value) * props.gratuity/100.0)
      setPrice(priceWithGratuity);
      let payout = calculatePrice(event.target.value, 1, 0, 0, props.gratuity);
      let payoutPremiumPrice = calculatePrice(event.target.value, 1.5, 0, 0.05, props.gratuity);
      let payoutPremiumPlusPrice = calculatePrice(event.target.value, 2, 0, 0.1, props.gratuity);
      let payoutFourPrice = calculatePrice(event.target.value, 4, 0, props.four_session_discount, props.gratuity);
      let payoutEightPrice = calculatePrice(event.target.value, 8, 0, props.eight_session_discount, props.gratuity);
      let payoutTwelvePrice = calculatePrice(event.target.value, 12, 0, props.twelve_session_discount, props.gratuity);
      setStandard(payout);
      setPremium(payoutPremiumPrice);
      setPremiumPlus(payoutPremiumPlusPrice);
      setFourSessions(payoutFourPrice);
      setEightSessions(payoutEightPrice);
      setTwelveSessions(payoutTwelvePrice);
    };

    function calculatePrice(price, multiple, saves, discount, gratuity){
      let numberPrice = parseFloat(price)
      let priceSaves = numberPrice * saves
      let priceI = numberPrice + priceSaves
      let priceC = priceI * multiple;
      let priceDiscount = priceC * discount;
      let priceWithGratuity = priceC * gratuity/100.0
      let fee = (priceC-priceDiscount+priceWithGratuity) * 0.2;
      let priceWG = priceC + priceWithGratuity
      let payout = priceWG - fee - priceDiscount;
      return payout.toFixed(2);
    };

    return (
      <div className="price-calculator-mt w-full p-4 md:p-0">
        <h2 className="text-gray-800 text-center">Enter a rate to calculate your payout and client cost</h2>
        <h2 className="text-gray-800 text-center">(${props.range_min} - ${props.range_max})</h2>
        <div className="text-center">
          <input className={inputFirefox} type="number" id={"price-mt-int-" + price_to} defaultValue={props.price} step="1" name={"price-mt-int-" + price_to} min={props.range_min} max={props.range_max} onChange={handlePriceInputChange}></input>
          <input className={"inline-display range-width-70 " + rangeFirefox} type="range" id={"price-mt-" + price_to} defaultValue={props.price} step="1" name={"price-mt-" + price_to} min={props.range_min} max={props.range_max} onChange={handlePriceChange}></input>
        </div>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tr className="bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th className="px-4 py-4 border">{props.provider_type}</th>
            <th className="px-4 py-4 border">You Receive</th>
            <th className="px-4 py-4 border">Client Pays</th>
          </tr>
          <tr className="bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td className="px-4 py-4 border">60 Minutes</td>
            <td className="px-4 py-4 border">${Math.round(standard).toFixed(2)}</td>
            <td className="px-4 py-4 border">${price.toFixed(2)}</td>
          </tr>
          <tr className="bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td className="px-4 py-4 border">90 Minutes</td>
            <td className="px-4 py-4 border">${Math.round(premium).toFixed(2)}</td>
            <td className="px-4 py-4 border">${Math.round((price*1.5) - Math.round(price *1.5 * 0.05)).toFixed(2)}</td>
          </tr>
          <tr className="bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td className="px-4 py-4 border">120 Minutes </td>
            <td className="px-4 py-4 border">${Math.round(premiumPlus).toFixed(2)}</td>
            <td className="px-4 py-4 border">${Math.round((price*2) - Math.round(price * 2 * 0.1)).toFixed(2)}</td>
          </tr>
          <tr className="bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td className="px-4 py-4 border">4 Sessions</td>
            <td className="px-4 py-4 border">${Math.round(fourSessions).toFixed(2)}</td>
            <td className="px-4 py-4 border">${Math.round((4*price) - Math.round(4*price * 0.05)).toFixed(2)}</td>
          </tr>
          <tr className="bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td className="px-4 py-4 border">8 Sessions</td>
            <td className="px-4 py-4 border">${Math.round(eightSessions).toFixed(2)}</td>
            <td className="px-4 py-4 border">${Math.round((8*price) - Math.round(8*price * 0.1)).toFixed(2)}</td>
          </tr>
          <tr className="bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td className="px-4 py-4 border">12 Sessions</td>
            <td className="px-4 py-4 border">${Math.round(twelveSessions).toFixed(2)}</td>
            <td className="px-4 py-4 border">${Math.round((12*price) - Math.round(12*price * 0.15)).toFixed(2)}</td>
          </tr>
        </table>
      </div>
    );
};

export default MassageTherapistCalculator;