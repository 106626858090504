import React, { useState } from "react";

const PriceCalculator = (props) => {
    const userAgent = navigator.userAgent.toLowerCase();
    const rangeFirefox = userAgent.includes("firefox") ? 'bg-blue-200 appearance-none h-1 rounded-full align-middle' : '';
    const inputFirefox = userAgent.includes("firefox") ? 'w-1/6' : '';
    let price_to = props.main_price;
    if(price_to == true){
      price_to = 'main_price'
    } else {
      price_to = 'secondary_price'
    }
    let payoutInit = calculatePrice(props.provider_price, 1, 0);
    let payoutPartnetPriceInit = calculatePrice(props.provider_price, 1.5, 0);
    let payoutGroupPriceInit = calculatePrice(props.provider_price, 2, 0);
    let payoutLargeGroupPriceInit = calculatePriceLargerGroup(props.provider_price, 2, 6);
    let [payoutFourPriceInit, payoutFourPriceClientInit] = calculatePriceClient(props.provider_price, 4, props.four_session_discount);
    let [payoutEightPriceInit, payoutEightPriceClientInit] = calculatePriceClient(props.provider_price, 8, props.eight_session_discount);
    let [payoutTwelvePriceInit, payoutTwelvePriceClientInit] = calculatePriceClient(props.provider_price, 12, props.twelve_session_discount);
    const [price, setPrice] = useState(props.provider_price);
    const [nrPeople, setNrPeople] = useState(6);
    const [largeGroupPrice, setLargeGroupPrice] = useState(payoutLargeGroupPriceInit);
    const [standard, setStandard] = useState(payoutInit);
    const [partnerPrice, setParnerPrice] = useState(payoutPartnetPriceInit);
    const [groupPrice, setGroupPrice] = useState(payoutGroupPriceInit);
    const [[fourSessions, fourSessionsClient], setFourSessions] = useState([payoutFourPriceInit,payoutFourPriceClientInit]);
    const [[eightSessions, eightSessionsClient], setEightSessions] = useState([payoutEightPriceInit, payoutEightPriceClientInit]);
    const [[twelveSessions, twelveSessionsClient], setTwelveSessions] = useState([payoutTwelvePriceInit, payoutTwelvePriceClientInit]);
    const handlePriceChange = event => {
      let inputInt = document.getElementById("price-int-" + price_to);
      inputInt.value = event.target.value;
      let inputMainPrice = document.getElementById("main-price-input");
      let inputSecondaryPrice = document.getElementById("secondary-price-input");
      if(price_to === "main_price"){
        inputMainPrice.value = event.target.value;
      } else {
        inputSecondaryPrice.value = event.target.value;
      }
      setPrice(parseFloat(event.target.value));
      let payout = calculatePrice(event.target.value, 1, 0);
      let payoutPartnetPrice = calculatePrice(event.target.value, 1.5, 0);
      let payoutGroupPrice = calculatePrice(event.target.value, 2, 0);
      let payoutLargeGroupPrice = calculatePriceLargerGroup(event.target.value, 2, 6);
      let [payoutFourPrice, payoutFourPriceClient] = calculatePriceClient(event.target.value, 4, props.four_session_discount);
      let [payoutEightPrice, payoutEightPriceClient] = calculatePriceClient(event.target.value, 8, props.eight_session_discount);
      let [payoutTwelvePrice, payoutTwelvePriceClient] = calculatePriceClient(event.target.value, 12, props.twelve_session_discount);
      setStandard(payout);
      setParnerPrice(payoutPartnetPrice);
      setGroupPrice(payoutGroupPrice);
      setFourSessions([payoutFourPrice, payoutFourPriceClient]);
      setEightSessions([payoutEightPrice, payoutEightPriceClient]);
      setTwelveSessions([payoutTwelvePrice, payoutTwelvePriceClient]);
      setLargeGroupPrice(payoutLargeGroupPrice);
    };

    const handlePriceInputChange = event => {
      let submitB = document.getElementById("submit-rate-btn");
      let inputRange = document.getElementById("price-" + price_to);
      let inputMainPrice = document.getElementById("main-price-input");
      let inputSecondaryPrice = document.getElementById("secondary-price-input");
      if(price_to === "main_price"){
        inputMainPrice.value = event.target.value;
      } else {
        inputSecondaryPrice.value = event.target.value;
      }
      inputRange.value = event.target.value;
      if(event.target.value < props.range_min || event.target.value > props.range_max){
        submitB.setAttribute('disabled', 'true');
        submitB.style.backgroundColor = 'gray';
        event.target.style.backgroundColor = 'pink';
      } else {
        submitB.removeAttribute('disabled');
        submitB.style.backgroundColor = '#06325e';
        event.target.style.backgroundColor = 'white';
      }
      setPrice(parseFloat(event.target.value));
      let payout = calculatePrice(event.target.value, 1, 0);
      let payoutPartnetPrice = calculatePrice(event.target.value, 1.5, 0);
      let payoutGroupPrice = calculatePrice(event.target.value, 2, 0);
      let payoutLargeGroupPrice = calculatePriceLargerGroup(event.target.value, 2, 6);
      let [payoutFourPrice, payoutFourPriceClient] = calculatePriceClient(event.target.value, 4, props.four_session_discount);
      let [payoutEightPrice, payoutEightPriceClient] = calculatePriceClient(event.target.value, 8, props.eight_session_discount);
      let [payoutTwelvePrice, payoutTwelvePriceClient] = calculatePriceClient(event.target.value, 12, props.twelve_session_discount);
      setStandard(payout);
      setParnerPrice(payoutPartnetPrice);
      setGroupPrice(payoutGroupPrice);
      setFourSessions([payoutFourPrice, payoutFourPriceClient]);
      setEightSessions([payoutEightPrice, payoutEightPriceClient]);
      setTwelveSessions([payoutTwelvePrice, payoutTwelvePriceClient]);
      setLargeGroupPrice(payoutLargeGroupPrice);
    };


    const handleNrPeopleChange = event => {
      if(event.target.value < 6 || event.target.value > 20){
        event.target.style.backgroundColor = 'pink';
      } else {
        event.target.style.backgroundColor = 'white';
      }
      setNrPeople(parseInt(event.target.value));
      let payout = calculatePriceLargerGroup(price, 2, parseInt(event.target.value))
      setLargeGroupPrice(payout);
    };

    function calculatePrice(price, multiple, discount){
      let numberPrice = parseFloat(price)
      let priceC = numberPrice * multiple;
      let priceDiscount = priceC * discount;
      let fee = priceC * 0.2;
      let payout = priceC - fee - priceDiscount;
      return payout.toFixed(2);
    };
    
    function calculatePriceClient(price, multiple, discount){
      let numberPrice = parseFloat(price)
      let priceC = numberPrice * multiple;
      let priceDiscount = priceC * discount;
      let client_payout = priceC - priceDiscount;
      let fee = client_payout * 0.2;
      let payout = client_payout - fee;
      return [payout.toFixed(2), client_payout.toFixed(2)];
    };

    function calculatePriceLargerGroup(price, multiple, nr_people){
      let numberPrice = parseFloat(price)
      let diff = (nr_people - 5) * 10;
      let priceC = numberPrice * multiple;
      let priceD = priceC + diff;
      let fee = priceD * 0.2;
      let payout = priceD - fee;
      return payout.toFixed(2);
    };

    return (
      <div className="price-calculator w-full p-4 md:p-0">
        <h2 className="text-gray-800 text-center">Enter a rate to calculate your payout and client cost</h2>
        <h2 className="text-gray-800 text-center">(${props.range_min} - ${props.range_max})</h2>
        <div className="text-center">
          <input className={inputFirefox} type="number" id={"price-int-" + price_to} defaultValue={props.price} step="1" name={"price-int-" + price_to} min={props.range_min} max={props.range_max} onChange={handlePriceInputChange}></input>
          <input className={"inline-display range-width-70 " + rangeFirefox} type="range" id={"price-" + price_to} defaultValue={props.price} step="1" name={"price-" + price_to} min={props.range_min} max={props.range_max} onChange={handlePriceChange}></input>
        </div>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tr className="bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th className="px-4 py-4 border">{props.provider_type}</th>
            <th className="px-4 py-4 border">You Receive</th>
            <th className="px-4 py-4 border">Client Pays</th>
          </tr>
          { props.provider_type!="Health Coach" && props.provider_type!="Life Coach" && props.provider_type!="Nutritionist" &&
          <tr className="bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td className="px-4 py-4 border">1-on-1</td>
            <td className="px-4 py-4 border">${Math.round(standard).toFixed(2)}</td>
            <td className="px-4 py-4 border">${Math.round(price).toFixed(2)}</td>
          </tr>
          }
          <tr className={props.partner_group === false ? "hidden" : "bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"}>
            <td className="px-4 py-4 border">Partner</td>
            <td className="px-4 py-4 border">${Math.round(partnerPrice).toFixed(2)}</td>
            <td className="px-4 py-4 border">${(price * 1.5).toFixed(2)}</td>
          </tr>
          <tr className={props.small_group === false ? "hidden" : "bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"}>
            <td className="px-4 py-4 border">Small group (3-5 people)</td>
            <td className="px-4 py-4 border">${Math.round(groupPrice).toFixed(2)}</td>
            <td className="px-4 py-4 border">${(price * 2).toFixed(2)}</td>
          </tr>
          <tr className="bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td className="px-4 py-4 border">4 Sessions</td>
            <td className="px-4 py-4 border">${Math.round(fourSessions).toFixed(2)}</td>
            <td className="px-4 py-4 border">${Math.round(fourSessionsClient).toFixed(2)}</td>
          </tr>
          <tr className="bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td className="px-4 py-4 border">8 Sessions</td>
            <td className="px-4 py-4 border">${Math.round(eightSessions).toFixed(2)}</td>
            <td className="px-4 py-4 border">${Math.round(eightSessionsClient).toFixed(2)}</td>
          </tr>
          <tr className={ props.provider_type === "Reiki practitioner" ? "hidden" : "bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"}>
            <td className="px-4 py-4 border">12 Sessions</td>
            <td className="px-4 py-4 border">${Math.round(twelveSessions).toFixed(2)}</td>
            <td className="px-4 py-4 border">${Math.round(twelveSessionsClient).toFixed(2)}</td>
          </tr>
        </table>
        <h2 className={props.large_group === false ? "hidden" : "text-gray-500 text-sm text-center pt-4 pb-2"}><b>Large Group Rate (6+ people)</b></h2>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tr className={props.large_group === false ? "hidden" : "bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"}>
            <td className="px-4 py-4 border">
              <span className="pr-4">Number of people:</span> 
              <input className={props.large_group === false ? "hidden" : "inline-display " + rangeFirefox} type="number" id="nr-people" defaultValue="6" step="1" name="nr-people" min="6" max="20" onChange={handleNrPeopleChange}></input>
            </td>
            <td className="px-4 py-4 border">${Math.round(largeGroupPrice).toFixed(2)}</td>
            <td className="px-4 py-4 border">${Math.round((price * 2) + (nrPeople - 5) * 10).toFixed(2)}</td>
          </tr>
        </table>
      </div>
    );
};

export default PriceCalculator;