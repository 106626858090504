import React from "react";
import Swal from "sweetalert2";
import { InlineWidget, CalendlyEventListener } from "react-calendly";

function NewAppointment({ user, provider, charge_id, created_by }) {
  const onEventScheduled = ({ data: event }) => {
    if (event && event.event === "calendly.event_scheduled") {
      fetch(`/api/v1/appointments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          provider_id: provider.id,
          charge_id: charge_id,
          event_uri: event.payload.event.uri,
          invitee_uri: event.payload.invitee.uri,
        }),
      })
        .then((response) => {
          if (response.ok) {
            displaySuccessDialog(event.payload.event.uri, provider);
          } else {
            console.log("Error creating booking: ", response);
            displayErrorDialog(response.message || "Could not create appointment.");
          }
        })
        .catch((error) => {
          console.log("Error creating booking: ", error);
          displayErrorDialog(error.message || "Could not perform action.");
        });
    }
  };

  const displayErrorDialog = (error) => {
    Swal.fire({
      icon: "error",
      title: "Something went wrong!",
      html: `Your appointment with ${provider.firstName} ${provider.lastName} is Rejected <br>\
        ${error} <br>\
        <hr style="width:30%"> Please try again. <br><br>`,
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
    });
  }

  async function displaySuccessDialog(event_uri, provider) {
    let response = await fetch(event_uri, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + provider.token,
        "Content-Type": "application/json",
      },
    });
    let data = await response.json();
    const start_date = new Date(data.resource.start_time);
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    let day = weekday[start_date.getDay()];
    const timezoneAbrreviations = { "ATLANTIC STANDARD TIME": "AST", "EASTERN STANDARD TIME": "EST", "EASTERN DAYLIGHT TIME": "EDT", "CENTRAL STANDARD TIME": "CST",
      "CENTRAL DAYLIGHT TIME": "CDT", "MOUNTAIN STANDARD TIME": "MST", "MOUNTAIN DAYLIGHT TIME": "MDT", "PACIFIC STANDARD TIME": "PST", "PACIFIC DAYLIGHT TIME": "PDT",
      "ALASKA TIME": "AKST", "ALASKA DAYLIGHT TIME": "AKDT", "HAWAII STANDARD TIME": "HST", "HAWAII-ALEUTIAN STANDARD TIME": "HAST", "HAWAII-ALEUTIAN DAYLIGHT TIME": "HADT",
      "SAMOA STANDARD TIME": "SST", "SAMOA DAYLIGHT TIME": "SDT", "CHAMORRO STANDARD TIME": "CHST"}
    var zone = start_date.toLocaleTimeString('en-us',{timeZoneName:'long'}).toUpperCase();
    var zone = zone.split(' ')
    zone.shift();
    zone.shift();
    var joined_zone = zone.join(' ');
    var checkTimeZone = timezoneAbrreviations[joined_zone];
    if(checkTimeZone) {
      var abbrTimeZone = checkTimeZone;
    } else {
      var abbrTimeZone = start_date.toLocaleTimeString('en-us',{timeZoneName:'short'});
      abbrTimeZone = abbrTimeZone.split(' ');
      abbrTimeZone.shift();
      abbrTimeZone.shift();
    }
    var hours = start_date.getHours();
    var minutes = start_date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + '' + ampm;
    Swal.fire({
      icon: "success",
      iconColor: "#1B95F9",
      title: "Success",
      html: `Your appointment with ${provider.firstName} ${provider.lastName} \
        on ${day}, ${month[start_date.getMonth()]} ${start_date.getDate()}, ${start_date.getFullYear()}  \
        at ${strTime} ${abbrTimeZone} is Confirmed`,
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
    });
  }

  return (
    <div className="App mb-5">
      <CalendlyEventListener onEventScheduled={onEventScheduled} />
      <InlineWidget
        url={`${provider.calendly_url}?hide_gdpr_banner=1`}
        prefill={{
          name: `${user.firstName} ${user.lastName}`,
          email: `${user.email}`,
          customAnswers: {
            a1: `${created_by}`,
          },
        }}
      />
    </div>
  );
}

export default NewAppointment;