import React, { useState } from "react";

const StarRating = (props) => {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [text, setText] = useState('');
    const handleTextChange = event => {
      const inputText = event.target.value;
      if (inputText.length > 1000){
          setText(inputText.substring(0,1000));
      } else {
          setText(inputText);
      }
    };
    const handleSubmit = event => {
      event.preventDefault();
    };
    

    return (
      <div className="star-rating">
        <h2 class = "text-gray-800">How was your experince with me?</h2>

        {[...Array(5)].map((star, index) => {
          index += 1;
          return (
            <button
              type="button"
              key={index}
              className={index <= (hover || rating) ? "on" : "off"}
              onClick={() => setRating(index)}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(rating)}
            >
              <span className="star">&#9733;</span>
            </button>
          );
        })}

        <form onSubmit={handleSubmit}>
          <h2 class = "text-gray-800 text-sm pt-2">Want to add a brief description?</h2>
          <div className="input-group">

            <textarea
              class = "rounded-lg  mb-2 w-full max-w-xl"
              onChange={handleTextChange}
              placeholder="Write a review"
              value={text}
              rows ={3}
              onInput={(e) => {
                  const lineHeight = parseInt(getComputedStyle(e.target).lineHeight);
                  const lastLineFilled = (e.target.scrollHeight - e.target.scrollTop) % lineHeight === 0;
                  if (lastLineFilled) {
                      e.target.rows += 1;
                  }
              }}
            ></textarea>

            <button
              type="submit"
              class="button-send-review border border-gray-300 px-10 text-white font-bold text-base rounded-lg py-2.5 md:mx-5 my-5 md:mb-20"
              style={{ verticalAlign: "middle" }}
              onClick={() => {
                const formData = new FormData();
                formData.append('_method',  'POST')
                formData.append('provider_id', props.provider_id)

                formData.append('rating_stars', rating);
                formData.append('rating_comment', text);

                $.ajax({
                  url: '/providers/rate_me',
                  type: 'POST',
                  data: formData,
                  processData: false,
                  cache: false,
                  contentType: false,
                })}}>Save</button>

          </div>
        </form>
      </div>
    );
};

export default StarRating;