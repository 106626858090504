// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "flowbite"
import "chartkick/chart.js"
import "chartkick/highcharts"
import "chartkick"
import Calendar from '@toast-ui/calendar'
import 'tui-date-picker/dist/tui-date-picker.css'
import 'tui-time-picker/dist/tui-time-picker.css'

//= require Chart.min
//= require jquery
//= require select2
//= require mapkick.bundle
require("@nathanvda/cocoon")

import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
// flatpickr
import flatpickr from 'flatpickr'
import "mapkick/bundle"
require("flatpickr/dist/flatpickr.css");


Rails.start()
Turbolinks.start()
ActiveStorage.start()
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

window.calendarInit = function() {
  const container = document.getElementById('calendar');
  const options = {
    defaultView: 'week',
    isReadOnly: true,
    useDetailPopup: true,
    navigation: true,
    week: {
      eventView: ['time'],
      taskView: false,
    },
  };

  const calendar = new Calendar(container, options);
  return calendar;
}

window.calendarMobileInit = function() {
  const container = document.getElementById('calendar-mobile');
  const options = {
    defaultView: 'day',
    isReadOnly: true,
    useDetailPopup: true,
    navigation: true,
    week: {
      eventView: ['time'],
      taskView: false,
    },
  };

  const calendar = new Calendar(container, options);
  return calendar;
}


// Select2 config
 
const formatResult = (sub) => {
  var $sub = $(
    '<li class="w-screen">' + sub.text +'</li>'
  );
  return $sub;
};

document.addEventListener('turbolinks:load', () => {
  flatpickr(".end-time-field", {
    enableTime: true,
    noCalendar: true,
    dateFormat: "h:i K",
  });

  flatpickr(".start-time-field", {
    altInput: true,
    altFormat: "m-d-Y h:i K",
    enableTime: true,
    dateFormat: "Y-m-d h:i K",
  });
})

window.addEventListener('turbolinks:load', () => {
  Fancybox.bind('[data-fancybox="gallery"]');
  Fancybox.bind('[data-fancybox="gallery-profile"]');
  $('.select_sub_categories').select2({
    width: "100%",
    templateResult: formatResult
  })
  $('.select-services').select2({
    width: "100%",
    templateResult: formatResult
  })
  $('.select2-primary').select2({
    width: "100%",
    templateResult: formatResult
  })
  $('.select2-secondary').select2({
    width: "100%",
    templateResult: formatResult
  })
  $('#provider_secondary_type').select2({
    width: "100%",
    templateResult: formatResult
  })

  $('.select2-states').select2({
    width: "100%",
    templateResult: formatResult
  })

  $('.select2-states-client').select2({
    width: "100%",
    templateResult: formatResult,
    placeholder: 'State*'
  })

  $('.select2-settings').select2({
    width: "100%",
    templateResult: formatResult,
    placeholder: 'Setting*'
  })

  $('.select2-selection__rendered').css('line-height', '36px');
  $('.select2-selection--single').css('height', '40px');
  $('.select2-selection__arrow ').css('height', '39px');

  $('.select2-selection--multiple').css('overflow', 'hidden');

  setTimeout(function(){
    $('#alert-notice').fadeOut('slow', function(){
      $(this).remove();
    });
  }, 15000);
})

$(document).on('click', '.read-more', function(e) {
  e.preventDefault();
  $(this).siblings('.truncate-comment').hide();
  $(this).siblings('.full-comment').show();
  $(this).siblings('.read-less').show();
  $(this).siblings('.read-more').hide();
  $(this).hide();
});

$(document).on('click', '.read-less', function(e) {
  e.preventDefault();
  $(this).siblings('.full-comment').hide();
  $(this).siblings('.truncate-comment').show();
  $(this).siblings('.read-more').show();
  $(this).siblings('.read-less').hide();
  $(this).hide();
});
